// This is a skeleton starter React component generated by Plasmic.
// This file is owned by you, feel free to edit as you see fit.
import React, { useState, useEffect } from "react";
import { PlasmicSidebar } from "./plasmic/collect_wise_draft/PlasmicSidebar";
import { Link, useHistory } from "react-router-dom"
import { useAuth } from "../contexts/AuthContext";
import { db } from "../firebase";
import { collection, query, where, onSnapshot } from "firebase/firestore";

function Sidebar_(props, ref) {
  const { currentUser } = useAuth();
  const history = useHistory();
  const [hasStripeCustomerId, setHasStripeCustomerId] = useState(false);

  useEffect(() => {
    if (!currentUser) return;

    const q = query(
      collection(db, "userInfo"),
      where("user", "==", currentUser.uid)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      if (snapshot.empty) {
        setHasStripeCustomerId(false);
        return;
      }

      const userInfo = snapshot.docs[0].data();
      setHasStripeCustomerId(!!userInfo.stripe_customer_id);
    });

    return () => unsubscribe();
  }, [currentUser]);

  return <PlasmicSidebar root={{ ref }} {...props}  
  dashboardButton={{
    onClick: () => history.push("/")
  }}
  paymentsButton={{
    onClick: () => history.push("/paymentslist")
  }}
  contractsButton={{
    onClick: () => history.push("/contractslist")
  }}
  clientsButton={{
    onClick: () => { 
      if (currentUser.uid === "cg0KYF8bHvSuyK7BFwPjCrwm0Dp2"){
        history.push('/alldebtors')
      } else {
        history.push("/debtorslist") 
      }
    }
  }}
  settingsButton={{
    onClick: () => history.push("/settings/billing")
  }}
  rolesBtn={{
    onClick: () => history.push("/roles")
  }}
  settingsButton2={{
    onClick: () => history.push("/usage"),
    style: { display: hasStripeCustomerId ? "flex" : "none" }
  }}
  />;
}

const Sidebar = React.forwardRef(Sidebar_);

export default Sidebar;
