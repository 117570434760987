import React, { useState, useEffect } from 'react';
import { Card, CardContent, Typography, Grid, Box, CircularProgress, Alert, Container, Paper, Divider, useTheme } from '@mui/material';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';
import { useAuth } from '../contexts/AuthContext';

const VOICEMAIL_HOURLY_RATE = 3.3;
const RPC_HOURLY_RATE = 5.5;
const RVM_RATE = 0.16;
const RVM_BATCH_SIZE = 10;

const UsagePage = () => {
    const [usageData, setUsageData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const [summary, setSummary] = useState(null);
    const { currentUser } = useAuth();

    useEffect(() => {
        const fetchUsageData = async () => {
            try {
                console.log("Current user info:", currentUser);
                const token = await currentUser.getIdToken();
                console.log("User token:", token);
                const userId = currentUser.uid;
                console.log("User ID from token:", userId);
                
                const response = await fetch(`https://us-central1-collectwise-dev.cloudfunctions.net/billingFrontend-getMeterSummaries?userId=${userId}`);
                const data = await response.json();
                setUsageData(data);
                calculateSummary(data);
            } catch (err) {
                setError('Failed to fetch usage data');
                console.error('Error:', err);
            } finally {
                setLoading(false);
            }
        };

        if (currentUser) {
            fetchUsageData();
        }
    }, [currentUser]);

    const calculateSummary = (data) => {
        const durationMeter = data.find(m => m.meterId === 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU');
        const countMeter = data.find(m => m.meterId === 'mtr_61S8ZsA6iYsRBDR1W41Kx3vje6v88Ecq');
        const rpcDurationMeter = data.find(m => m.meterId === 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK');
        const rpcCountMeter = data.find(m => m.meterId === 'mtr_61S8ZsE0afbj2JOPt41Kx3vje6v88Wim');

        const totalSeconds = durationMeter?.data?.data?.reduce((sum, item) => sum + item.aggregated_value, 0) || 0;
        const totalVoicemails = countMeter?.data?.data?.reduce((sum, item) => sum + item.aggregated_value, 0) || 0;
        const totalRpcSeconds = rpcDurationMeter?.data?.data?.reduce((sum, item) => sum + item.aggregated_value, 0) || 0;
        const totalRpcCalls = rpcCountMeter?.data?.data?.reduce((sum, item) => sum + item.aggregated_value, 0) || 0;

        const voicemailHours = Math.ceil(totalSeconds / 3600);
        const rpcHours = Math.ceil(totalRpcSeconds / 3600);
        const voicemailCost = voicemailHours * VOICEMAIL_HOURLY_RATE;
        const rpcCost = rpcHours * RPC_HOURLY_RATE;
        const avgTimePerVoicemail = totalVoicemails > 0 ? totalSeconds / totalVoicemails : 0;
        const avgCostPerVoicemail = totalVoicemails > 0 ? voicemailCost / totalVoicemails : 0;
        const avgRpcDuration = totalRpcCalls > 0 ? totalRpcSeconds / totalRpcCalls : 0;

        // Calculate RVM costs per time period
        const rvmCostData = countMeter?.data?.data?.map(item => ({
            ...item,
            rvm_cost: Math.ceil(item.aggregated_value / RVM_BATCH_SIZE) * RVM_RATE
        })) || [];
        const totalRvmCost = rvmCostData.reduce((sum, item) => sum + item.rvm_cost, 0);

        setSummary({
            totalSeconds,
            totalVoicemails,
            voicemailHours,
            rpcHours,
            voicemailCost,
            rpcCost,
            avgTimePerVoicemail,
            avgCostPerVoicemail,
            totalRpcSeconds,
            totalRpcCalls,
            avgRpcDuration,
            totalRvmCost,
            rvmCostData
        });
    };

    const formatTimestamp = (timestamp) => {
        return format(new Date(timestamp * 1000), 'MMM d, yyyy HH:mm');
    };

    const formatDuration = (seconds) => {
        if (seconds < 60) {
            return `${Math.round(seconds)}s`;
        } else if (seconds < 3600) {
            return `${Math.round(seconds / 60)}m`;
        } else {
            const hours = Math.floor(seconds / 3600);
            const minutes = Math.floor((seconds % 3600) / 60);
            return minutes > 0 ? `${hours}h ${minutes}m` : `${hours}h`;
        }
    };

    const formatDurationForAxis = (seconds) => {
        if (seconds < 60) {
            return `${Math.round(seconds)}s`;
        } else if (seconds < 3600) {
            return `${Math.round(seconds / 60)}m`;
        } else {
            return `${(seconds / 3600).toFixed(1)}h`;
        }
    };

    const formatCurrency = (amount) => {
        return new Intl.NumberFormat('en-US', {
            style: 'currency',
            currency: 'USD'
        }).format(amount);
    };

    const processChartData = (meterData, includeRvmCost = false) => {
        if (!meterData?.data?.data) return [];
        
        return meterData.data.data
            .map(item => ({
                time: formatTimestamp(item.start_time),
                value: item.aggregated_value,
                startTime: item.start_time,
                endTime: item.end_time,
                rvm_cost: includeRvmCost ? Math.ceil(item.aggregated_value / RVM_BATCH_SIZE) * RVM_RATE : undefined,
                duration: item.aggregated_value
            }))
            .sort((a, b) => a.startTime - b.startTime);
    };

    const getMeterTitle = (meterId) => {
        switch (meterId) {
            case 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU':
                return 'Voicemail Duration';
            case 'mtr_61S8ZsA6iYsRBDR1W41Kx3vje6v88Ecq':
                return 'Dropped Voicemails';
            case 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK':
                return 'RPC Call Duration';
            case 'mtr_61S8ZsE0afbj2JOPt41Kx3vje6v88Wim':
                return 'RPC Call Count';
            default:
                return meterId;
        }
    };

    const getMeterIcon = (meterId) => {
        return null;
    };

    const formatValue = (meterId, value) => {
        switch (meterId) {
            case 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU':
            case 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK':
                return formatDuration(value);
            case 'mtr_61S8ZsA6iYsRBDR1W41Kx3vje6v88Ecq':
            case 'mtr_61S8ZsE0afbj2JOPt41Kx3vje6v88Wim':
                return value;
            default:
                return value;
        }
    };

    if (loading) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" minHeight="80vh">
                <CircularProgress />
            </Box>
        );
    }

    if (error) {
        return (
            <Container maxWidth="lg" sx={{ mt: 4 }}>
                <Alert severity="error">{error}</Alert>
            </Container>
        );
    }

    return (
        <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            {/* Header with Logo */}
            <Box 
                display="flex" 
                alignItems="center" 
                mb={6}
                sx={{
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                    pb: 3
                }}
            >
                <img 
                    src="/collectwise-blue-logo-large.png"
                    alt="CollectWise Logo" 
                    style={{ 
                        height: '40px',
                        marginRight: '24px'
                    }}
                />
            </Box>

            {/* High Level Cost Summary */}
            <Paper 
                elevation={0} 
                sx={{ 
                    mb: 4,
                    p: 4,
                    borderRadius: 3,
                    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                    boxShadow: '20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff'
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 600, color: '#1a237e', mb: 3 }}>
                    Cost Overview
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                Voicemail Cost
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {formatCurrency((summary?.voicemailCost || 0) + (summary?.totalRvmCost || 0))}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {summary?.voicemailHours || 0} hours at ${VOICEMAIL_HOURLY_RATE}/hr + RVM
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                RPC Cost
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {formatCurrency(summary?.rpcCost || 0)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                {summary?.rpcHours || 0} hours at ${RPC_HOURLY_RATE}/hr
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                Total Cost
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {formatCurrency((summary?.voicemailCost || 0) + (summary?.rpcCost || 0) + (summary?.totalRvmCost || 0))}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                Combined Services
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            </Paper>

            {/* Voicemail Analytics */}
            <Paper 
                elevation={0} 
                sx={{ 
                    mb: 4,
                    p: 4,
                    borderRadius: 3,
                    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                    boxShadow: '20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff'
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 600, color: '#1a237e', mb: 3 }}>
                    Voicemail Analytics
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                Total Voicemails
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {summary?.totalVoicemails || 0}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                messages
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                Average Duration
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {formatDuration(summary?.avgTimePerVoicemail || 0)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                per voicemail
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                RVM Infra Cost
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {formatCurrency(summary?.totalRvmCost || 0)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                ${RVM_RATE} per {RVM_BATCH_SIZE} voicemails
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={4}>
                    <Grid container spacing={4}>
                        {usageData?.filter(meter => 
                            meter.meterId === 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU' || 
                            meter.meterId === 'mtr_61S8ZsA6iYsRBDR1W41Kx3vje6v88Ecq'
                        ).map((meter) => (
                            <Grid item xs={12} md={6} key={meter.meterId}>
                                <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e', mb: 2 }}>
                                        {getMeterTitle(meter.meterId)}
                                    </Typography>
                                    <Box height={300}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart data={processChartData(meter)}>
                                                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                                                <XAxis 
                                                    dataKey="time" 
                                                    angle={-45}
                                                    textAnchor="end"
                                                    height={70}
                                                    interval={0}
                                                    tick={{ fontSize: 12, fill: '#666' }}
                                                />
                                                <YAxis 
                                                    tick={{ fontSize: 12, fill: '#666' }}
                                                    tickFormatter={(value) => 
                                                        (meter.meterId === 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU') 
                                                            ? formatDurationForAxis(value)
                                                            : (meter.meterId === 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK')
                                                                ? formatDurationForAxis(value)
                                                                : value
                                                    }
                                                />
                                                <Tooltip 
                                                    labelFormatter={(label) => `Time: ${label}`}
                                                    formatter={(value, name, props) => {
                                                        if (meter.meterId === 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU' ||
                                                            meter.meterId === 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK') {
                                                            return [formatDuration(value), getMeterTitle(meter.meterId)];
                                                        }
                                                        return [value, getMeterTitle(meter.meterId)];
                                                    }}
                                                    contentStyle={{
                                                        backgroundColor: 'white',
                                                        border: '1px solid #e0e0e0',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                                                    }}
                                                />
                                                <Bar 
                                                    dataKey="value" 
                                                    fill="#1a237e"
                                                    radius={[4, 4, 0, 0]}
                                                    maxBarSize={40}
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                        {/* <Grid item xs={12}>
                            <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                                <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e', mb: 2 }}>
                                    RVM Cost Over Time
                                </Typography>
                                <Box height={300}>
                                    <ResponsiveContainer width="100%" height="100%">
                                        <BarChart data={processChartData(usageData?.find(m => m.meterId === 'mtr_61S8ZsA6iYsRBDR1W41Kx3vje6v88Ecq'), true)}>
                                            <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                                            <XAxis 
                                                dataKey="time" 
                                                angle={-45}
                                                textAnchor="end"
                                                height={70}
                                                interval={0}
                                                tick={{ fontSize: 12, fill: '#666' }}
                                            />
                                            <YAxis 
                                                tick={{ fontSize: 12, fill: '#666' }}
                                                tickFormatter={(value) => formatCurrency(value)}
                                            />
                                            <Tooltip 
                                                labelFormatter={(label) => `Time: ${label}`}
                                                formatter={(value) => [formatCurrency(value), "RVM Cost"]}
                                                contentStyle={{
                                                    backgroundColor: 'white',
                                                    border: '1px solid #e0e0e0',
                                                    borderRadius: '8px',
                                                    boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                                                }}
                                            />
                                            <Bar 
                                                dataKey="rvm_cost" 
                                                fill="#1a237e"
                                                radius={[4, 4, 0, 0]}
                                                maxBarSize={40}
                                            />
                                        </BarChart>
                                    </ResponsiveContainer>
                                </Box>
                            </Box>
                        </Grid> */}
                    </Grid>
                </Box>
            </Paper>

            {/* RPC Analytics */}
            <Paper 
                elevation={0} 
                sx={{ 
                    mb: 4,
                    p: 4,
                    borderRadius: 3,
                    background: 'linear-gradient(145deg, #ffffff 0%, #f8f9fa 100%)',
                    boxShadow: '20px 20px 60px #d9d9d9, -20px -20px 60px #ffffff'
                }}
            >
                <Typography variant="h5" sx={{ fontWeight: 600, color: '#1a237e', mb: 3 }}>
                    RPC Analytics
                </Typography>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                Total RPC Calls
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {summary?.totalRpcCalls || 0}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                calls
                            </Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                            <Typography variant="subtitle2" color="textSecondary" sx={{ mb: 1 }}>
                                Average Duration
                            </Typography>
                            <Typography variant="h4" sx={{ fontWeight: 600, color: '#1a237e' }}>
                                {formatDuration(summary?.avgRpcDuration || 0)}
                            </Typography>
                            <Typography variant="body2" color="textSecondary">
                                per call
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box mt={4}>
                    <Grid container spacing={4}>
                        {usageData?.filter(meter => 
                            meter.meterId === 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK' || 
                            meter.meterId === 'mtr_61S8ZsE0afbj2JOPt41Kx3vje6v88Wim'
                        ).map((meter) => (
                            <Grid item xs={12} md={6} key={meter.meterId}>
                                <Box sx={{ p: 2, borderRadius: 2, border: '1px solid', borderColor: 'divider' }}>
                                    <Typography variant="h6" sx={{ fontWeight: 600, color: '#1a237e', mb: 2 }}>
                                        {getMeterTitle(meter.meterId)}
                                    </Typography>
                                    <Box height={300}>
                                        <ResponsiveContainer width="100%" height="100%">
                                            <BarChart data={processChartData(meter)}>
                                                <CartesianGrid strokeDasharray="3 3" stroke="#f0f0f0" />
                                                <XAxis 
                                                    dataKey="time" 
                                                    angle={-45}
                                                    textAnchor="end"
                                                    height={70}
                                                    interval={0}
                                                    tick={{ fontSize: 12, fill: '#666' }}
                                                />
                                                <YAxis 
                                                    tick={{ fontSize: 12, fill: '#666' }}
                                                    tickFormatter={(value) => 
                                                        (meter.meterId === 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU') 
                                                            ? formatDurationForAxis(value)
                                                            : (meter.meterId === 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK')
                                                                ? formatDurationForAxis(value)
                                                                : value
                                                    }
                                                />
                                                <Tooltip 
                                                    labelFormatter={(label) => `Time: ${label}`}
                                                    formatter={(value, name, props) => {
                                                        if (meter.meterId === 'mtr_61S8Zs6LJI6hSwiyq41Kx3vje6v88UtU' ||
                                                            meter.meterId === 'mtr_61S8Zs27i7dMP4NUj41Kx3vje6v88BKK') {
                                                            return [formatDuration(value), getMeterTitle(meter.meterId)];
                                                        }
                                                        return [value, getMeterTitle(meter.meterId)];
                                                    }}
                                                    contentStyle={{
                                                        backgroundColor: 'white',
                                                        border: '1px solid #e0e0e0',
                                                        borderRadius: '8px',
                                                        boxShadow: '0 4px 20px rgba(0,0,0,0.1)'
                                                    }}
                                                />
                                                <Bar 
                                                    dataKey="value" 
                                                    fill="#1a237e"
                                                    radius={[4, 4, 0, 0]}
                                                    maxBarSize={40}
                                                />
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </Box>
                                </Box>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            </Paper>
        </Container>
    );
};

export default UsagePage;
