import React, { useEffect, useState } from "react";
import {useAuth} from "./contexts/AuthContext"
import { BrowserRouter as Router, Switch, Route, Redirect} from "react-router-dom";
import PaymentsPage from "./components/PaymentsPage";
import EscrowSubmission from "./components/EscrowSubmission";
import ClientsList from "./components/ClientsList";
import PaymentsList from "./components/PaymentsList";
import ContractsList from "./components/ContractsList";
import ClientInstance from "./components/ClientInstance";
import ContractInstance from "./components/ContractInstance";
import LoginPage from "./components/LoginPage"
import SignupPage from "./components/SignupPage"
import {AuthProvider} from "./contexts/AuthContext"
import PrivateRoute from "./components/PrivateRoute";
import ResetPassword from "./components/ResetPassword";
import TestNewPage from "./components/TestNewPage"
import Search from "./components/Search";
import TestModal from "./components/TestModal";
import LoadingSpinner from "./components/LoadingSpinner";
import Dashboard from "./components/Dashboard";
import AnotherTest from "./components/AnotherTest";
import EscrowPaymentsPage from "./components/EscrowPaymentsPage"
import EscrowPaymentsPagePreview from "./components/EscrowPaymentsPagePreview"
import ContractsList2 from "./components/ContractsList2"
import PaymentsList4 from "./components/PaymentsList4"
import PaymentInstance from "./components/PaymentInstance"
import OnboardingController from "./components/OnboardingController"
import NewOnboardingPage from "./components/NewOnboardingPage";
import OnboardingBusinessDetails from "./components/OnboardingBusinessDetails"
import OnboardingSoleProprietorDetails from "./components/OnboardingSoleProprietorDetails"
import OnboardingPersonalDetails from "./components/OnboardingPersonalDetails"
import OnboardingBankingDetailsBiz from "./components/OnboardingBankingDetailsBiz"
import OnboardingBankingDetailsPersonal from "./components/OnboardingBankingDetailsPersonal";
import OnboardingReviewBiz from "./components/OnboardingReviewBiz"
import OnboardingReviewPersonal from "./components/OnboardingReviewPersonal"
import {OnboardingProvider} from "./contexts/OnboardingContext"
import ConfirmEmail from "./components/ConfirmEmail"
import GetStarted from "./components/GetStarted"
import EmailVerifiedRoute from "./components/EmailVerifiedRoute";
import OnboardVerifiedRoute from "./components/OnboardVerifiedRoute";
import InvoiceDetails from "./components/InvoiceDetails"
import InvoiceDetails2 from "./components/InvoiceDetails2"
import EscrowPaymentsPage2 from "./components/EscrowPaymentsPage2"
import CurrentInvoicePaymentsPagePreview from "./components/CurrentInvoicePaymentsPagePreview"
import CurrentInvoicePaymentsPage from "./components/CurrentInvoicePaymentsPage"
import BillingSettings from "./components/BillingSettings"
import OnboardingPersonalDetailsRetry from "./components/OnboardingPersonalDetailsRetry";
import OnboardingSoleProprietorDetailsRetry from "./components/OnboardingSoleProprietorDetailsRetry";
import OnboardingBusinessDetailsRetry from "./components/OnboardingBusinessDetailsRetry"
import OnboardingPersonalDetailsDocument from "./components/OnboardingPersonalDetailsDocument"
import AuthTest from "./components/AuthTest";
import OnboardingPersonalDetailsDocumentReceived from "./components/OnboardingPersonalDetailsDocumentReceived"
import OnboardingBusinessDetailsDocument from "./components/OnboardingBusinessDetailsDocument"
import OnboardingBusinessDetailsDocumentReceived from "./components/OnboardingBusinessDetailsDocumentReceived"
import OnboardingOwnerDetailsRetry from "./components/OnboardingOwnerDetailsRetry"
import OnboardingOwnerDetailsDocument from "./components/OnboardingOwnerDetailsDocument";
import VerifyEmail from "./components/VerifyEmail";
import Signup2 from "./components/Signup2"
import Login2 from "./components/Login2"
import { auth } from "./firebase";
import OnboardingPersonalSuccess from "./components/OnboardingPersonalSuccess"
import OnboardingBizSuccess from "./components/OnboardingBizSuccess"
import OnboardingPersonalFail from "./components/OnboardingPersonalFail"
import OnboardingBizFail from "./components/OnboardingBizFail"
import OnboardingBizSuccess2 from "./components/OnboardingBizSuccess2";
import OnboardingBizApplicationReceived from "./components/OnboardingBizApplicationReceived"
import OnboardingPersonalApplicationReceived from "./components/OnboardingPersonalApplicationReceived"
import OnboardingBusinessDetailsRetryManual from "./components/OnboardingBusinessDetailsRetryManual";
// import Developer from "./components/Developer";
import Developer2 from "./components/Developer2";
import Roles from "./components/Roles"
import NewCardForm from "./components/NewCardForm";
import NewSetupDetails from "./components/NewSetupDetails";
import NewOnboardingBankingDetails from "./components/NewOnboardingBankingDetails";
import NewPlanInformation from "./components/NewPlanInformation";
import NewConfirmIdentity from "./components/NewConfirmIdentity";
import NewApplicationReceived from "./components/NewApplicationReceived";
import createChildUserPage from "./components/CreateChildUserPage"
import SubscriptionDetails from "./components/SubscriptionDetails";
import SubscriptionPaymentsPagePreview from "./components/SubscriptionPaymentsPagePreview"
import SubscriptionPaymentsPage from "./components/SubscriptionPaymentsPage"
import DebtorSubmission from "./components/DebtorSubmission";
import DebtorSubmissionLoading from "./components/DebtorSubmissionLoading";
import DebtorsList from "./components/DebtorsList";
import DebtorsListLoading from "./components/DebtorsListLoading";
import DebtorDetails from "./components/DebtorDetails";
import ViewDebtor from "./components/ViewDebtor";
import UpdateInformation from "./components/UpdateInformation";
import CardForm from "./components/CardForm";
import AllDebtors from "./components/AllDebtors";
import MasterViewDebtor from "./components/MasterViewDebtor";
import FolderUploader from "./components/FolderUploader";
import UsagePage from "./pages/usage";

function App() {


  return(
      <Router>
      <AuthProvider>
      
      <Switch>
        <Route path="/login" component={Login2}/>
        <Route path="/signup" component={Signup2}/>
        <Route path="/signout" component={function SignOut(){useEffect(() => auth.signOut(), []); return <Redirect to="/" />}} />
        <Route path="/forgotpassword" component={ResetPassword}/>
        <OnboardVerifiedRoute path="/anothertes" component={AnotherTest}/>
        <Route path="/verifyEmail" component={VerifyEmail}/>
        <Route path="/milestonepayments/:paymentId" component={EscrowPaymentsPage}/>
        <Route path="/invoicepayments/:paymentId" component={CurrentInvoicePaymentsPage}/>
        <Route path="/subscriptionpayments/:paymentId" component={SubscriptionPaymentsPage}/>
        <Route path="/test-this" component={TestNewPage}/>
        <Route path="/onboarding-propay" component={OnboardingBizSuccess2}/>
        <Route path="/authtest" component={AuthTest}/>
        <Route path="/newcardform" component={NewCardForm}/>
        <Route path="/createChildUserPage" component={createChildUserPage}/>
        <Route path="/testpayment" component={CardForm}/>
        


        <PrivateRoute path="/verify-email" component={ConfirmEmail}/>
        <EmailVerifiedRoute path="/get-started" component={GetStarted}/>


        <EmailVerifiedRoute path="/onboarding" component={NewSetupDetails}/>
        <EmailVerifiedRoute path="/onboarding-banking-details" component={NewOnboardingBankingDetails}/>
        <EmailVerifiedRoute path="/onboarding-plan-information" component={NewPlanInformation}/>
        <EmailVerifiedRoute path="/onboarding-confirm-identity" component={NewConfirmIdentity}/>
        <EmailVerifiedRoute path="/application-received" component={NewApplicationReceived}/>


        <EmailVerifiedRoute path="/verify-owners" component={OnboardingOwnerDetailsRetry}/> 
        <EmailVerifiedRoute path="/verify-owners-document" component={OnboardingOwnerDetailsDocument}/>
        <EmailVerifiedRoute path="/account-approved" component={OnboardingPersonalSuccess}/>
        <EmailVerifiedRoute path="/business-approved" component={OnboardingBizSuccess}/>
        <EmailVerifiedRoute path="/account-unapproved" component={OnboardingPersonalFail}/>
        <EmailVerifiedRoute path="/business-unapproved" component={OnboardingBizFail}/>



        
        <OnboardVerifiedRoute exact path="/" component={Dashboard}/>
        <OnboardVerifiedRoute path="/settings/billing" component={BillingSettings}/>
        <OnboardVerifiedRoute path="/roles" component={Roles}/>
        <OnboardVerifiedRoute path="/developer" component={Developer2}/>
        <OnboardVerifiedRoute path="/submitdebts" component={DebtorSubmission}/>
        <OnboardVerifiedRoute path="/submitdebtsloading" component={DebtorSubmissionLoading}/>
        <OnboardVerifiedRoute path="/folderuploader" component={FolderUploader}/>
        
        <OnboardVerifiedRoute path="/testsearch" component={Search}/>
        <PrivateRoute path="/testmodal" component={TestModal}/>

        <OnboardVerifiedRoute path="/invoice-preview" component={CurrentInvoicePaymentsPagePreview}/>
        <OnboardVerifiedRoute path="/subscription-preview" component={SubscriptionPaymentsPagePreview}/>

        <OnboardVerifiedRoute path="/escrowpayment-preview" component={EscrowPaymentsPagePreview}/>
        <OnboardVerifiedRoute path="/contractsubmission" component={EscrowSubmission}/>
        <OnboardVerifiedRoute path="/invoicesubmission" component={InvoiceDetails}/>
        <OnboardVerifiedRoute path ="/subscriptionsubmission" component={SubscriptionDetails}/>
        <OnboardVerifiedRoute path="/updateinformation" component={UpdateInformation}/>

        <OnboardVerifiedRoute path="/viewdebtor/:id" component={ViewDebtor}/>
        <OnboardVerifiedRoute path="/masterviewdebtor/:id" component={MasterViewDebtor}/>
        <OnboardVerifiedRoute path="/debtordetails" component={DebtorDetails}/>
        <OnboardVerifiedRoute path="/clientslist" component={ClientsList}/>
        <OnboardVerifiedRoute path="/debtorslist" component={DebtorsList}/>
        <OnboardVerifiedRoute path="/debtorslistloading" component={DebtorsListLoading}/>
        <OnboardVerifiedRoute path="/paymentslist" component={PaymentsList}/>
        <OnboardVerifiedRoute path="/contractslist" component={ContractsList2}/>
        <OnboardVerifiedRoute path="/debtor/:id" component={ClientInstance}/>
        <OnboardVerifiedRoute path="/contract/:contractId" component={ContractInstance}/>
        <OnboardVerifiedRoute path="/payment/:paymentDataId" component={PaymentInstance}/>
        <OnboardVerifiedRoute path="/alldebtors" component={AllDebtors}/>
        <OnboardVerifiedRoute path="/usage" component={UsagePage}/>
        
      </Switch>
      
      </AuthProvider>
    </Router>
  )
}

export default App;